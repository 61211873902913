<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    Manage Testimonials
                                </h4>
                              <router-link :to="{ name: 'dashboard' }">
                                Dashboard
                              </router-link>
                              \
                              Testimonials
                            </div>
                            <!--                        <div class="col-2 ">-->
                            <!--                            <router-link :to="{name:'dashboard'}" class="btn btn-primary">-->
                            <!--                                <i class="fas fa-arrow-left"></i>-->
                            <!--                                Back-->
                            <!--                            </router-link>-->
                            <!--                        </div>-->
                            <!--                        <div class="col-4">-->
                            <!--                            <h4>Manage Slider Image</h4>-->
                            <!--                        </div>-->
                            <div class="col-6 text-right" >
                                <router-link v-if="checkIsAccessible('testimonial', 'create')" :to="{name:'testimonial-new'}" class="btn btn-primary ml-2">
                                    Add Testimonial
                                </router-link>
                            </div>
                            <form @submit.prevent="getTestimonial"  class="row mt-5 mb-5 bg-filter">
                                <div class="col-4">
                                    <v-text-field label="Title"
                                                  type="text"
                                                  dense
                                                  outlined
                                                  v-model="search.title"></v-text-field>
                                </div>
                            <div class="col-4">
                                    <v-select label="Status" outlined dense :items="active_statuses" item-text="title"
                                              item-value="value"
                                              v-model="search.active"></v-select>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" @click="getTestimonial">Search</button>
                                </div>
                            </form>
                            <div class="col-12 mt-2">
                                <table class="table table-stripe">
                                    <thead>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Rating</th>
                                    <th>Action</th>
                                    </thead>
                                    <draggable @change="sort" v-model="testimonials" class="text-left" tag="tbody">
                                        <tr v-for="(testimonial, index) in testimonials" :key="index">
                                            <td>
                                                <i class="cursor-drag fas fa-sort handle pr-2"></i>
                                                <img
                                                        v-if="testimonial.image_path.thumb"
                                                        :src="testimonial.image_path['thumb']"
                                                        alt
                                                        style="height:75px"
                                                        class="img-thumbnail"
                                                />
                                            </td>
                                            <td>
                                                <a href="#" @click="editTestimonial(testimonial.id)" class="mr-2"> {{ testimonial.title }}</a>
                                                <i class="fas fa-circle"
                                                   :class="testimonial.is_active?'dot-active':'dot-inactive'"></i>
                                            </td>

                                            <td>
                                                <v-rating
                                                        v-model="testimonial.rating"
                                                        color="yellow darken-3"
                                                        background-color="grey darken-1"
                                                        empty-icon="$ratingFull"
                                                        half-increments
                                                        readonly
                                                        hover
                                                        large
                                                ></v-rating>

                                               </td>


                                            <td>
                                                <b-dropdown
                                                        variant="link"
                                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                        no-caret
                                                        right
                                                        no-flip
                                                >
                                                    <template v-slot:button-content>
                                                        <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                                    </template>
                                                    <!--begin::Navigation-->
                                                    <div class="navi navi-hover ">

                                                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('testimonial', 'edit')">
                                                            <a href="#" class="navi-link"
                                                               @click="editTestimonial(testimonial.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                                <span class="navi-text">  Edit</span>
                                                            </a>
                                                        </b-dropdown-text>
                                                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('testimonial', 'delete')">
                                                            <a href="#" class="navi-link"
                                                               @click="deleteTestimonial(testimonial.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                                                <span class="navi-text">  Delete</span>
                                                            </a>
                                                        </b-dropdown-text>

                                                    </div>
                                                    <!--end::Navigation-->
                                                </b-dropdown>

                                            </td>
                                        </tr>
                                    </draggable>
                                </table>
                            </div>
                        </div>
                        <div class="row" v-if="testimonials.length > 0">
                            <div class="col-12 text-center">
                                <b-pagination
                                        @input="getTestimonial"
                                        v-model="page"
                                        :total-rows="total"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                ></b-pagination>
                                <!--  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
    import TestimonialService from "@/services/cms/testimonial/TestimonialService";
    import draggable from 'vuedraggable'

    const testimonialService = new TestimonialService();
    export default {
        name: "Testimonial",
        display: "Table",
        order: 8,
        components: {draggable},
        data() {
          return {
              testimonials: [],
              active_statuses: [
                  {title: 'Active', value: "active"},
                  {title: 'In Active', value: "in_active"}
              ],
              page: null,
              total: null,
              perPage: null,
              search: {
                  active: "active",
              }
          };
        },
        mounted() {
            this.getTestimonial();
        },
        methods: {
            getTestimonial() {
                testimonialService.paginate(this.search, this.page).then(response => {
                    this.testimonials = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                });
            },
            editTestimonial(testimonialId) {
                this.$router.push({name: "testimonial-update", params: {id: testimonialId}});
            },
            sort() {
                testimonialService.sort(this.testimonial).then((res) => {
                    this.$snotify.success("Sorted");
                    this.getTestimonial()
                }).catch(err => {
                    this.$snotify.success("Sorted Error");
                })
            },
            deleteTestimonial(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item permanently?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            testimonialService
                                .delete(id)
                                .then(response => {
                                    this.$snotify.success("Deleted successfully");

                                    this.getTestimonial();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            }
        }
    };
</script>
